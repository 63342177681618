import React, { useContext } from 'react';
import logo from "../../assets/img/logo.webp";
import { Langs } from "../../enums";
import { GlobalContext } from "../../App";

type TFooter = {
    title: string,
    home: string,
    about: string,
    services: string,
    clients: string,
    partners: string,
    contact: string,
    copyright: string,
};

const contentsMap = new Map<Langs, TFooter>([
    [Langs.RU, {
        title: "Ваши монеты",
        home: "Главная",
        about: "О нас",
        services: "Услуги",
        clients: "Клиенты",
        partners: "Партнеры",
        contact: "Контакты",
        copyright: "© Все права защищены. Создано",
    }],
    [Langs.EN, {
        title: "Your coins",
        home: "Home",
        about: "About",
        services: "Services",
        clients: "Clients",
        partners: "Partners",
        contact: "Contact",
        copyright: "© All rights reserved. Created by",
    }]
]);

function Footer() {
    const { lang } = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TFooter;

    return (
        <footer className="bg-gray-900 text-white py-6">
            <div className="container mx-auto px-4">
                <div className="flex xl:flex-row flex-col justify-between items-center">
                    <div>
                        <a href="/"
                           className="flex items-center xl:justify-start justify-center space-x-3 text-2xl xl:text-start rtl:space-x-reverse">
                            <img src={logo} className="h-20" alt="Flowbite Logo" />
                        </a>
                    </div>
                    <div className="flex gap-4">
                        <a href="/" className="hover:text-orange-600">{contents.home}</a>
                        <a href="#about" className="hover:text-orange-600">{contents.about}</a>
                        <a href="#service" className="hover:text-orange-600">{contents.services}</a>
                        <a href="#clients" className="hover:text-orange-600">{contents.clients}</a>
                        <a href="#partners" className="hover:text-orange-600">{contents.partners}</a>
                        <a href="#contacts" className="hover:text-orange-600">{contents.contact}</a>
                    </div>
                </div>
                <div className="mt-6 border-t border-gray-700 pt-4">
                    <p className="text-center xl:p-0 px-4 text-gray-400">
                        {contents.copyright} <a className="hover:font-semibold" target="_blank" rel="noreferrer" href="https://uft.uz/">UFT | Unique Future Technology</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
