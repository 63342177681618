import React, {useContext} from 'react';
import vector1 from '../../assets/img/icons/Vector1.svg'
import vector2 from '../../assets/img/icons/Vector2.svg'
import vector3 from '../../assets/img/icons/Vector3.svg'
import vector4 from '../../assets/img/icons/Vector5.svg'
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";


type TService = {
    main: string,
    sub: string,
    box1: string,
    box2: string,
    box3: string,
    box4: string,
};


const contentsMap = new Map<Langs, TService>([
    [Langs.RU, {
        main: "Наши услуги",
        sub: "Исследование и поставка высококачественного оборудования, запасных и изнашиваемых деталей практически для любой отрасли. Наши высококвалифицированные инженеры проработают и найдут подходящие товары для вашего оборудования.\n" +
            "У нас есть склад для хранения срочных запасных и изнашиваемых деталей для наших клиентов.\n" +
            "Наша сервисная команда также поможет вам установить и запустить ваше оборудование.",
        box1: "Исследование",
        box2: "Поставка оборудования",
        box3: "Техническая поддержка",
        box4: "Поставка запасных частей"
    }],
    [Langs.EN, {
        main: "Our Services",
        sub: "Research and delivery very good quality equipment, spare and wear parts for almost any industry. Our good qualified engineers will work through and find suitable goods for your equipment." +
            "We have a warehouse to keep urgent spare and wear parts for our customers. Our service team will also help you to install and make start up of your equipment.",
        box1: "Research",
        box2: "Supply of equipment",
        box3: "Technical support",
        box4: "Spare parts delivery"
    }]
]);


function Service() {

    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TService;

    return (
        <div id="service" className="service scroll-mt-20 text-center mt-24">
            <h1 className="xl:text-5xl text-4xl font-bold pt-24">{contents.main}</h1>
            <p className="pt-6 w-8/12 mx-auto text-lg pb-10">{contents.sub}</p>

            <div className="flex flex-wrap gap-3 justify-center xl:mt-10 mt-5">
                <div
                    className="group xl:w-80 xl:h-80 w-40 h-40 bg-white text-black shadow-lg rounded-lg flex flex-col items-center justify-center transition-all duration-300 hover:shadow-2xl">
                    <div
                        className="text-orange-600 group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">
                        <img src={vector1} alt="icon1"/>
                    </div>
                    <p className="mt-4 text-xl font-semibold group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">{contents.box1}</p>
                </div>

                <div
                    className="group xl:w-80 xl:h-80 w-40 h-40 bg-white text-black shadow-lg rounded-lg flex flex-col items-center justify-center transition-all duration-300 hover:shadow-2xl">
                    <div
                        className="text-orange-600 group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">
                        <img src={vector2} alt="icon2"/>
                    </div>
                    <p className="mt-4 text-xl font-semibold group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">{contents.box2}</p>
                </div>

                <div
                    className="group xl:w-80 xl:h-80 w-40 h-40 bg-white text-black shadow-lg rounded-lg flex flex-col items-center justify-center transition-all duration-300 hover:shadow-2xl">
                    <div
                        className="text-orange-600 group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">
                        <img src={vector3} alt="icon3"/>
                    </div>
                    <p className="mt-4 text-xl font-semibold group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">{contents.box3}</p>
                </div>

                <div
                    className="group xl:w-80 xl:h-80 w-40 h-40 bg-white text-black shadow-lg rounded-lg flex flex-col items-center justify-center transition-all duration-300 hover:shadow-2xl">
                    <div
                        className="text-orange-600 group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">
                        <img src={vector4} alt="icon4"/>
                    </div>
                    <p className="mt-4 text-xl font-semibold group-hover:text-gray-900 transition-transform duration-300 transform group-hover:scale-125">{contents.box4}</p>
                </div>
            </div>

            {/*<div className="flex items-center mt-20 w-11/12 mx-auto justify-center gap-5">*/}
            {/*    <h1 className="xl:text-3xl text-xl font-bold">Learn more about our services</h1>*/}
            {/*    <Link to="about" className="bg-orange-600 hover:bg-orange-500 text-white font-bold py-3 px-8">*/}
            {/*        Learn More*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </div>
    );
}

export default Service;