import React, {createContext, useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Navbar from "./components/Nabvar/Navbar";
import Hero from "./components/Hero/Hero";
import Video from "./components/Video/Video";
import Service from "./components/Service/Service";
import Clients from "./components/Clients/Clients";
import Partners from './components/Partners/Partners';
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";
import ScrollTop from "./components/ScrollTopButton/ScrollTop";
import About from './components/About/About';
import MyForm from "./components/MyForm/MyForm";
import {Langs} from "./enums"; // Import your new About component


export type TGlobalContext = {
    lang: Langs;
    setLang: (lang: Langs) => void;
}

export const GlobalContext = createContext<TGlobalContext>({
    lang: Langs.RU,
    setLang: (lang: Langs) => {}
});

function App() {
    const [lang, setLang] = useState<Langs>((window.localStorage.getItem('lang') as Langs) ?? Langs.EN);

    return (
        <GlobalContext.Provider value={{ lang, setLang }}>

        <Router>
            <Navbar
                setLang={(newLang: Langs) => {
                    setLang(newLang);
                    window.localStorage.setItem('lang', newLang.toString());
                }}
            />
            <ScrollTop/>
            <Routes>
                <Route path="/" element={
                    <>
                        <Hero/>
                        <Video/>
                        <Service/>
                        <Clients/>
                        <Partners/>
                        <MyForm/>
                        <Contacts/>
                    </>
                }/>
                <Route path="/about" element={<About/>}/> {/* Render the About component */}
            </Routes>
            <Footer/>
        </Router>
            </GlobalContext.Provider>
    );
}

export default App;
