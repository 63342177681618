import React, {useEffect} from 'react';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="max-w-7xl mx-auto px-4 py-8">

            <div className="py-4 px-6">
                <p className="text-gray-700">
                    <a href="/" className="hover:text-orange-600 transition duration-300">Home</a>
                    <span className="mx-2">/</span>
                    <span className="text-orange-600 font-semibold">About us</span>
                </p>
            </div>

            <h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
            <p className="text-lg text-center mb-8">
                We are a dedicated team committed to providing the best services for our clients. Our mission is to
                deliver high-quality solutions that exceed expectations and foster lasting relationships.
            </p>
            <h2 className="text-2xl font-semibold text-center mb-4">Meet Our Team</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">John Doe</h3>
                    <p className="text-gray-600">CEO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Jane Smith</h3>
                    <p className="text-gray-600">CTO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Mike Johnson</h3>
                    <p className="text-gray-600">CFO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Mike Johnson</h3>
                    <p className="text-gray-600">CFO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Mike Johnson</h3>
                    <p className="text-gray-600">CFO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Mike Johnson</h3>
                    <p className="text-gray-600">CFO</p>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-4">
                    <img src="https://via.placeholder.com/600x400" alt="Team Member 1"
                         className="w-full h-96 object-cover rounded-t-lg"/>
                    <h3 className="text-xl font-semibold mt-4">Mike Johnson</h3>
                    <p className="text-gray-600">CFO</p>
                </div>


            </div>

        </div>
    );
};

export default About;
