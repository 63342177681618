import {useContext, useEffect, useRef, useState} from 'react';
import icon1 from '../../assets/img/icons/ic-like.svg'
import icon2 from '../../assets/img/icons/ic-hand.svg'
import icon3 from '../../assets/img/icons/timer.svg'
import image1 from '../../assets/img/1.jpg'
import image2 from '../../assets/img/2.png'
import image3 from '../../assets/img/3.jpg'
import image4 from '../../assets/img/4.jpeg'
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";


type TVideo = {
    main: string,
    sub: string,
    box1: string,
    box2: string,
    box3: string,
    box1Sub: string,
    box2Sub: string,
    box3Sub: string,
    about: string,
};


const contentsMap = new Map<Langs, TVideo>([
    [Langs.RU, {
        main: "Мы OOO Concept Solution KS",
        sub: "мы найдем решение для любой Вашей концепции и идей",
        box1: "Качество",
        box1Sub: "Мы помогаем нашим клиентам найти качественное оборудование и запасные части к нему, которые отвечают требованиям наших клиентов",
        box2: "Безопасность",
        box2Sub: "Работа нашей компании соответствует всем международным стандартам безопасности",
        box3: "Долгосрочное",
        box3Sub: "Мы предоставляем долгосрочное обслуживание и поддержку нашим клиентам, включая гарантию на весь ассортимент продукции",
        about: "Наша компания была создана в 2022 году в качестве партнера по послепродажному обслуживанию в Узбекистане нескольких автопроизводителей, таких как Volkswagen, BYD, ZEEKR, FAW и др. из Китая и ЕС. \nНо со временем мы развиваемся и расширяем свои горизонты, охватывая все новые и новые сферы бизнеса, такие как нефтегазовая, горнодобывающая промышленность, производство строительных материалов, сервисное обслуживание оборудования и т.д. Мы помогаем нашим клиентам найти качественное оборудование и запасные части к нему, отвечающие требованиям наших клиентов."

    }],
    [Langs.EN, {
        main: "We are Concept Solutions KS LLC",
        sub: "we will find SOLUTION FOR ALL YOUR CONCEPTS",
        box1: "Quality",
        box1Sub: "We help our clients find quality equipment and spare parts for it that meet the requirements of our clients",
        box2: "Safety",
        box2Sub: "Our company's work complies with all international safety standards.",
        box3: "Longterm",
        box3Sub: "We provide longterm service and support to our customers including warranty for all product range",
        about: "Our company was created in 2022 as after market sales partner  in Uzbekistan for several automobile manufacturers such as Volkswagen, BYD, ZEEKR, FAW, etc. from China and EU. \nBut over time, we develop and expand our horizons, covering more and more new areas of business, such as oil and gas, mining, production of building materials, equipment service, etc. We help our clients find quality equipment and spare parts for it that meet the requirements of our clients."
    }]
]);


const ImagesCount = 4;

function Video() {
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TVideo;
    const imageBox = useRef<HTMLDivElement>(null);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(1);


    function animateImages(box: HTMLDivElement, index: number = 0): number {
        let count = index;
        for (let i = 0; i < box.children.length; i++) {
            if (box.children[i] instanceof HTMLImageElement) {
                if (++count === activeImageIndex) {
                    console.log(activeImageIndex)
                    const imageEl = box.children[i] as HTMLImageElement
                    setTimeout(() => {
                        imageEl.classList.remove('z-10');
                    }, 4000)
                    setTimeout(() => {
                        imageEl.classList.remove(`animated-${activeImageIndex}`);
                    }, 3000)
                    setTimeout(() => {
                        imageEl.classList.add(`animated-${activeImageIndex}`);
                    }, 100)
                    imageEl.classList.add('z-10');
                    return count
                }
            }
            count = animateImages(box.children[i] as HTMLDivElement, count);
            if (count >= activeImageIndex) {
                return count
            }
        }
        return count
    }

    useEffect(() => {
        if (imageBox.current instanceof HTMLDivElement) {
            animateImages(imageBox.current)
        }
    }, [activeImageIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImageIndex(prev => (prev++ === ImagesCount)? 1 : prev);
        }, 4000);
        return () => {
            clearInterval(interval)
        }
    }, []);

    return (
        <div className="video-section mt-5 text-center scroll-mt-14" id="about">
            <h1 className="xl:text-5xl text-4xl font-bold pt-16">{contents.main}</h1>
            <p className="pt-6 text-lg pb-7">{contents.sub}</p>

            <div className="flex gap-5 w-11/12 flex-wrap my-5 mx-auto justify-center">
                <div className="box xl:w-96 w-full flex flex-col">
                    <img className="w-11 mx-auto" src={icon1} alt="icon1"/>
                    <h2 className="mt-4 mb-3 text-xl font-bold">{contents.box1}</h2>
                    <p className="px-5">{contents.box1Sub}</p>
                </div>
                <div className="box xl:w-96 w-full flex flex-col">
                    <img className="w-11 mx-auto" src={icon2} alt="icon2"/>
                    <h2 className="mt-4 mb-3 text-xl font-bold">{contents.box2}</h2>
                    <p className="px-5">{contents.box2Sub}</p>
                </div>
                <div className="box xl:w-96 w-full flex flex-col">
                    <img className="w-11 mx-auto" src={icon3} alt="icon3"/>
                    <h2 className="mt-4 mb-3 text-xl font-bold">{contents.box3}</h2>
                    <p className="px-5">{contents.box3Sub}</p>
                </div>
            </div>


            <section className='w-full h-[45%] flex justify-center items-start'>
                <div ref={imageBox} className='w-2/5 h-full flex flex-col relative rounded-lg overflow-hidden'>
                    <div className="w-full h-1/2 flex">
                        <div className='w-1/2 h-full'>
                            <img src={image1} alt="" className='w-1/2 h-1/2 absolute object-cover transition-all duration-1000'/>
                        </div>
                        <div className='w-1/2 h-full'>
                            <img src={image2} alt="" className='w-1/2 h-1/2 absolute object-cover transition-all duration-1000'/>
                        </div>
                    </div>
                    <div className="w-full h-1/2 flex">
                        <div className='w-1/2 h-full'>
                            <img src={image3} alt="" className='w-1/2 h-1/2 absolute object-cover transition-all duration-1000'/>
                        </div>
                        <div className='w-1/2 h-full'>
                            <img src={image4} alt="" className='w-1/2 h-1/2 absolute object-cover transition-all duration-1000'/>
                        </div>
                    </div>
                </div>

                <div className='w-1/5 h-full bg-white flex flex-col justify-center items-center text-center m-5'>{contents.about}</div>
            </section>
        </div>
    );
}

export default Video;