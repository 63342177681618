import React, {useContext} from 'react';
import Slider from 'react-slick';
import heroImage1 from '../../assets/img/hero/1.webp';
import heroImage2 from '../../assets/img/hero/2.webp';
import heroImage3 from '../../assets/img/hero/3.webp';
import heroImage4 from '../../assets/img/hero/4.webp';
import heroImage5 from '../../assets/img/hero/5.webp';
import heroImage6 from '../../assets/img/hero/6.webp';
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";

type THero = {
    main: string,
    mainOrange: string,
    sub: string,
    button: string,
};


const contentsMap = new Map<Langs, THero>([
    [Langs.RU, {
        main: "Будущее за",
        mainOrange: 'Концептуальными решениями',
        sub: "Наши концептуальные решения это — начальная стадия проектирования, на которой принимаются решения, определяющие будущее вашей компании.",
        button: "отправить запрос"
    }],
    [Langs.EN, {
        main: "The Future lies in",
        mainOrange: 'Conceptual Solutions',
        sub: "Our conceptual solutions are the initial design stage where decisions are made that will shape the future of your company.",
        button: "Submit Request"
    }]
]);


const Hero: React.FC = () => {
    const images = [heroImage1, heroImage2, heroImage3, heroImage4, heroImage5, heroImage6,];
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as THero;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <div className="flex items-center relative">
            <Slider {...settings} className="w-full bg-black">
                {images.map((src, index) => (
                    <div key={index} className="hero">
                        <img
                            src={src}
                            alt={`Hero ${index + 1}`}
                            className="w-full h-full opacity-50 object-cover"
                        />
                    </div>
                ))}
            </Slider>
            <div className="xl:w-1/3 w-full px-6 xl:mx-36 xl:py-12 absolute">
                <h1 className="2xl:text-6xl text-4xl font-bold leading-tight text-white">
                    {contents.main} <span className="text-orange-600">{contents.mainOrange}</span>
                </h1>
                <p className="xl:text-xl text-md mt-7 mb-5 leading-normal text-white">
                    {contents.sub}
                </p>
                <div className="flex gap-4">
                    <button
                        className="mt-8 bg-orange-600 hover:bg-orange-700 text-white font-bold py-3 px-8 rounded-lg"
                        onClick={() => {
                            const myForm = document.getElementById('myForm');
                            if (myForm) {
                                myForm.scrollIntoView({behavior: 'smooth'});
                            }
                        }}
                    >
                        {contents.button}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
