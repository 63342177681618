import React, { useContext } from 'react';
import heroImage from "../../assets/img/bg-image.jpg";
import { Langs } from "../../enums";
import { GlobalContext } from "../../App";

type TMyForm = {
    title: string,
    nameLabel: string,
    namePlaceholder: string,
    phoneLabel: string,
    phonePlaceholder: string,
    emailLabel: string,
    emailPlaceholder: string,
    messageLabel: string,
    messagePlaceholder: string,
    agreeText: string,
    submitButton: string,
};

const contentsMap = new Map<Langs, TMyForm>([
    [Langs.RU, {
        title: "Быстрый способ обсудить детали",
        nameLabel: "Имя*",
        namePlaceholder: "Ваше имя",
        phoneLabel: "Телефон*",
        phonePlaceholder: "Ваш номер телефона",
        emailLabel: "Электронная почта*",
        emailPlaceholder: "Ваша рабочая почта",
        messageLabel: "Сообщение*",
        messagePlaceholder: "Ваше сообщение",
        agreeText: "Я согласен получать сообщения от Concept Solutions",
        submitButton: "ОТПРАВИТЬ ЗАПРОС"
    }],
    [Langs.EN, {
        title: "A quick way to discuss details",
        nameLabel: "Name*",
        namePlaceholder: "Your name",
        phoneLabel: "Phone*",
        phonePlaceholder: "Your phone number",
        emailLabel: "Email*",
        emailPlaceholder: "Your working email",
        messageLabel: "Message*",
        messagePlaceholder: "Your message",
        agreeText: "I agree to receive communications from Concept Solutions",
        submitButton: "SEND REQUEST"
    }]
]);

function MyForm() {
    const { lang } = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TMyForm;

    return (
        <div id="myForm" className="flex items-center justify-center h-[89vh] scroll-mt-[102px] bg-gray-100"
             style={{ background: `url(${heroImage})` }}>
            <div className="bg-white shadow-lg rounded-lg xl:p-8 p-4 xl:w-1/3 w-11/12">
                <h2 className="text-2xl font-bold mb-6">{contents.title}</h2>
                <form>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold mb-2" htmlFor="name">{contents.nameLabel}</label>
                        <input
                            type="text"
                            id="name"
                            placeholder={contents.namePlaceholder}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold mb-2" htmlFor="phone">{contents.phoneLabel}</label>
                        <input
                            type="tel"
                            id="phone"
                            placeholder={contents.phonePlaceholder}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold mb-2" htmlFor="email">{contents.emailLabel}</label>
                        <input
                            type="email"
                            id="email"
                            placeholder={contents.emailPlaceholder}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold mb-2" htmlFor="message">{contents.messageLabel}</label>
                        <textarea
                            id="message"
                            placeholder={contents.messagePlaceholder}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        ></textarea>
                    </div>
                    <div className="flex items-center mb-4">
                        <input type="checkbox" id="agree" className="mr-2" required />
                        <label htmlFor="agree" className="text-sm">
                            {contents.agreeText}
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600 transition duration-200"
                    >
                        {contents.submitButton}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default MyForm;
