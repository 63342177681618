import React, {useContext} from 'react';
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";
import etc from '../../assets/img/PARTNER/ЕТС.jpg'
import camozzi from '../../assets/img/PARTNER/Camozzi.png'
import huatao from '../../assets/img/PARTNER/Huatao PU.png'
import kingsbeads from '../../assets/img/PARTNER/King\'s Beads.png'
import vfiltration from '../../assets/img/PARTNER/V - Filtration.png'



type TPartners = {
    main: string,
    sub: string,
};


const contentsMap = new Map<Langs, TPartners>([
    [Langs.RU, {
        main: "Партнеры",
        sub: 'На данный момент мы являемся партнерами и дистрибьюторами нескольких компаний со всего мира: Camozzi (Италия), King\'s Beads (Китай), V - Filtration, Huatao PU и rubber products и др'
    }],
    [Langs.EN, {
        main: "Partners",
        sub: "Now we are partners and distributer of several companies from the whole world. They are Camozzi (Italy), King's Beads (China), V - Filtration, Huatao PU and rubber products, etc.",
    }]
]);


function Partners() {
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TPartners;

    return (
        <div className="partners text-center mt-20 xl:mb-72 mb-20 scroll-mt-64" id="partners">
            <h1 className="text-4xl font-bold">{contents.main}</h1>
            <p className="text-lg mx-auto mt-8 w-2/4">{contents.sub}</p>
            <div className="partners flex flex-wrap justify-center xl:gap-28 gap-16 mt-10">
                <img className="w-[10%] saturate-0 hover:saturate-100 object-contain transition duration-[500ms]" src={etc} alt="partner"/>
                <img className="w-[10%] saturate-0 hover:saturate-100 object-contain transition duration-[500ms]" src={camozzi} alt="partner"/>
                <img className="w-[10%] saturate-0 hover:saturate-100 object-contain transition duration-[500ms]" src={huatao} alt="partner"/>
                <img className="w-[10%] saturate-0 hover:saturate-100 object-contain transition duration-[500ms]" src={kingsbeads} alt="partner"/>
                <img className="w-[10%] saturate-0 hover:saturate-100 object-contain transition duration-[500ms]" src={vfiltration} alt="partner"/>
            </div>
        </div>
    );
}

export default Partners;