import logo from '../../assets/img/logo.webp';
import {useState, useRef, useEffect, useContext} from 'react';
import xIcon from '../../assets/img/icons/X-Bold.svg';
import hamburger from '../../assets/img/icons/text-column-wide.svg';
import {langs, Langs} from "../../enums";
import {GlobalContext} from "../../App";

type TNavbarProperties = {
    readonly setLang: (newLang: Langs) => void;
};


type TNavbar = {
    about: string,
    service: string,
    clients: string,
    partners: string,
    contacts: string,
};


const contentsMap = new Map<Langs, TNavbar>([
    [Langs.RU, {
        about: "О нас",
        service: "Сервисы",
        clients: "Клиенты",
        partners: "Партнеры",
        contacts: "Контакты"
    }],
    [Langs.EN, {
        about: "About",
        service: "Services",
        clients: "Clients",
        partners: "Partners",
        contacts: "Contact"
    }]
]);


export default function Navbar({setLang}: TNavbarProperties) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TNavbar;


    const dropdownRef = useRef<HTMLDivElement | null>(null); // Create a reference for the dropdown

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLanguageChange = (value: Langs) => {
        setLang(value);
        setIsOpen(false);
    };


    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <nav className="bg-white shadow-md py-3 px-6 sticky top-0 z-50 transition-transform duration-300">
            <div className="container mx-auto flex justify-between items-center">
                {/* Logo */}
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-20" alt="Flowbite Logo"/>
                </a>

                {/* Desktop Menu Items */}
                <ul className="hidden md:flex space-x-9 text-gray-700">
                    <li className="text-lg cursor-pointer font-medium block py-2 px-3 md:p-0 text-black hover:text-orange-600"
                        onClick={() => {
                            const about = document.getElementById('about');
                            if (about) {
                                about.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/';
                            }
                        }}>{contents.about}
                    </li>
                    <li
                        className="text-lg cursor-pointer font-medium block py-2 px-3 md:p-0 text-black hover:text-orange-600"
                        onClick={() => {
                            const service = document.getElementById('service');
                            if (service) {
                                service.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/';
                            }
                        }}>{contents.service}
                    </li>
                    <li
                        className="text-lg cursor-pointer font-medium block py-2 px-3 md:p-0 text-black hover:text-orange-600"
                        onClick={() => {
                            const clients = document.getElementById('clients');
                            if (clients) {
                                clients.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/';
                            }
                        }}>{contents.clients}
                    </li>
                    <li className="text-lg cursor-pointer font-medium block py-2 px-3 md:p-0 text-black hover:text-orange-600"
                        onClick={() => {
                            const partners = document.getElementById('partners');
                            if (partners) {
                                partners.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/';
                            }
                        }}>{contents.partners}
                    </li>
                    <li className="text-lg cursor-pointer font-medium block py-2 px-3 md:p-0 text-black hover:text-orange-600"
                        onClick={() => {
                            const contacts = document.getElementById('contacts');
                            if (contacts) {
                                contacts.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/';
                            }
                        }}>{contents.contacts}
                    </li>
                </ul>

                {/* Language Dropdown */}
                <div className="relative hidden md:flex items-center" ref={dropdownRef}>
                    <button
                        className="flex items-center text-black font-medium text-lg bg-white border-none focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {window.localStorage.getItem('lang') === 'en' ? 'English' : 'Русский'}

                        <span className="ml-2">▼</span>
                    </button>

                    {isOpen && (
                        <div className="absolute z-10 top-7 -right-1 mt-2 w-32 rounded-md shadow-lg bg-white">
                            <ul className="max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto">
                                {langs.map((el) => (
                                    <li key={"lang-" + el}>
                                        <button
                                            onClick={() => handleLanguageChange(el)}
                                            className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200 font-medium"
                                        >
                                            {el === Langs.EN ? 'English' : 'Русский'}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Hamburger Icon (for mobile) */}
                <div className="md:hidden">
                    <button onClick={toggleMobileMenu}>
                        {isMobileMenuOpen ? (
                            <img src={xIcon} alt="Close menu" className="h-8 w-8 text-gray-700"/>
                        ) : (
                            <img src={hamburger} alt="Open menu" className="h-8 w-8 text-gray-700"/>
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */
            }
            {
                isMobileMenuOpen && (
                    <div className="md:hidden flex flex-col items-center mt-4 space-y-4">
                        <ul className="text-center text-gray-700 space-y-4">
                            <li>
                                <a href="#about"
                                   className="block py-2 px-3 md:p-0 text-black hover:text-orange-600">{contents.about}</a>
                            </li>
                            <li>
                                <a href="#service"
                                   className="block py-2 px-3 md:p-0 text-black hover:text-orange-600">{contents.service}</a>
                            </li>
                            <li>
                                <a href="#clients"
                                   className="block py-2 px-3 md:p-0 text-black hover:text-orange-600">{contents.clients}</a>
                            </li>
                            <li>
                                <a href="#partners"
                                   className="block py-2 px-3 md:p-0 text-black hover:text-orange-600">{contents.partners}</a>
                            </li>
                            <li>
                                <a href="#contacts"
                                   className="block py-2 px-3 md:p-0 text-black hover:text-orange-600">{contents.contacts}</a>
                            </li>
                        </ul>

                        {/* Language Switcher for Mobile */}
                        <div className="flex items-center space-x-2">
                            <select
                                value={langs.find((el) => el === Langs.EN) || Langs.EN}
                                onChange={(e) => handleLanguageChange(e.target.value as Langs)}
                                className="text-gray-700 bg-white border-none focus:outline-none"
                            >
                                <option value={Langs.EN}>English (US)</option>
                                <option value={Langs.RU}>Русский</option>
                            </select>
                        </div>
                    </div>
                )
            }
        </nav>
    )
        ;
}
