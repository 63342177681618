import React, {useContext} from 'react';
import testImg from '../../assets/img/400x400.png'
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";
import uzKorGazImg from '../../assets/img/KLIENT/UzKorGaz.png'
import UMMCImg from '../../assets/img/KLIENT/НГМК - UMMC.png'
import JSCImg from '../../assets/img/KLIENT/AJ Kizilkumcement - JSC Kizilkumcement.png'
import crushTechImg from '../../assets/img/KLIENT/Crush Tech LLC.png'
import shurtanImg from '../../assets/img/KLIENT/Shurtan Gas.jpg'
import tremenduosImg from '../../assets/img/KLIENT/Tremendous LLC.png'
import uzgtlImg from '../../assets/img/KLIENT/UzGTL.jpg'
import AMMCImg from '../../assets/img/KLIENT/АГМК - AMMC.webp'


type TClients = {
    main: string,
    sub: string,
    companies: {name: string}[]
};


const contentsMap = new Map<Langs, TClients>([
    [Langs.RU, {
        main: "Наши клиенты",
        sub: "Алмалыкский горно-металлургический комбинат, Навоийский горно-металлургический комбинат, Кизилкумцемент, УзКорГаз, УзГТЛ, Шуртанский газохимический комплекс, ООО Crush Tech, ООО Tremendous и другие.",
        companies: [
            {
                name: 'UzKorGaz'
            }, {
                name: 'НГМК'
            }, {
                name: 'AJ Kizilkumcement'
            }, {
                name: 'Crush Tech LLC'
            }, {
                name: 'Shurtan Gas'
            }, {
                name: 'Tremendous LLC'
            }, {
                name: 'UzGTL'
            }, {
                name: 'АГМК'
            }
        ]
    }],
    [Langs.EN, {
        main: "Our Clients",
        sub: "Almalyk Mining and Metalurgical Company, Navoi Mining and Metalurgical Company, Kizilkumcement, UzKorGaz, UzGTL, Shurtan Gas chemical complex, Crush Tech LLC, Tremendous LLC and etc..",
        companies: [
            {
                name: 'UzKorGaz'
            }, {
                name: 'UMMC'
            }, {
                name: 'AJ Kizilkumcement'
            }, {
                name: 'Crush Tech LLC'
            }, {
                name: 'Shurtan Gas'
            }, {
                name: 'Tremendous LLC'
            }, {
                name: 'UzGTL'
            }, {
                name: 'AMMC'
            }
        ]
    }]
]);


function Clients() {
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TClients;


    return (
        <div className="clients pt-20 scroll-mt-12" id="clients">
            <div className="text-center">
                <h1 className="text-4xl font-bold">{contents.main}</h1>
                <p className="pt-4 w-1/2 mx-auto pb-10">{contents.sub}</p>
            </div>
            <div className="flex flex-wrap justify-center gap-[5%]">
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={uzKorGazImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[0].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={UMMCImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[1].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={JSCImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[2].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={crushTechImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[3].name}</h1>
                </div>

            </div>
            <div className="flex flex-wrap mt-6 justify-center gap-[5%]">
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={shurtanImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[4].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={tremenduosImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[5].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={uzgtlImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[6].name}</h1>
                </div>
                <div className="text-center flex flex-col justify-center items-center gap-5 bg-white shadow-lg rounded-xl">
                    <img src={AMMCImg} alt="client1" className="xl:w-64 xl:h-64 w-32 h-32 rounded"/>
                    <h1 className="text-xl font-bold hover:text-orange-600 pb-3 transition duration-[500ms]">{contents.companies[7].name}</h1>
                </div>
            </div>
        </div>
    );
}

export default Clients;